.gallery-component {
  background-color: #f9f9f9;
#gallery {
    padding: 50px 0;
  }
  
  h2 {
    font-size: 30px;
    font-weight: bold;
    margin-bottom: 20px;
  }
  
  .gallery {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    grid-gap: 20px;
  }
  
  img {
    width: 15rem;
    height: 10rem;
    
  @media screen and (max-width: 1000px) {
    max-width: 100%;
    height: 10rem;
  }
}
}
  