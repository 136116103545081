.projects-component {
  background-color: #f5f5f5;
  padding: 40px 0;

.container {
  max-width: 1200px;
  margin: 0 auto;
}

h2 {
  font-size: 30px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 20px;
  color: #d30000;
}

.projects-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* Display 4 cards in a line */
  grid-gap: 20px;
  margin-top: 20px;

}

.project {
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  border-radius: 5px;
  cursor: pointer;
}

.project img {
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-radius: 5px;
}

.project h3 {
  font-size: 1.3rem;
  font-weight: bold;
  margin-top: 10px;
}

.project p {
  font-size: 1rem;
  margin-top: 10px;
  color: #444;
}

.buttons-container {
  text-align: center;
  margin-top: 20px;
}

/* Responsive styles */
@media screen and (max-width: 1200px) {
  .projects-grid {
    grid-template-columns: repeat(2, 1fr); /* On smaller screens, display 2 cards in a line */
  }
}

@media screen and (max-width: 768px) {
  .projects-grid {
    grid-template-columns: 1fr; /* On even smaller screens, display 1 card in a line */
  }
}
}

.view-more-btn,
.view-less-btn {
  background-color: #d30000;
  color: #fff;
  font-size: 1rem;
  font-weight: bold;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.view-more-btn:hover,
.view-less-btn:hover {
  background-color: red;
}