.service-component {
    #services {
        background-color: #fff;
        padding: 50px 0;
      }
      
      h2 {
        font-size: 30px;
        font-weight: bold;
        margin-bottom: 20px;
        color: #d30000;
      }
      
      .services-grid {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
        grid-gap: 50px;
      }
      
      .service {
        background-color: #f5f5f5;
        padding: 20px;
        // text-align: center;
        border-radius: 5px;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
        transition: all 0.3s ease;
      }
      
      .service:hover {
        transform: translateY(-10px);
        box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.2);
      }
      
      .service i {
        font-size: 48px;
        margin-bottom: 20px;
        color: #00a0e9;
      }
      
      .service h3 {
        font-size: 22px;
        font-weight: bold;
        margin-bottom: 10px;
        color: #333;
      }
      
      .service p {
        font-size: 16px;
        color: #666;
        text-align: justify;
      }
}