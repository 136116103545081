/* src/WhyOrion.css */
.why-orion {
    background-color: #f8f8f8;
    padding: 50px 0;
    text-align: center;
  
  .container {
    // max-width: 960px;
    margin: 0 auto;
  }
  
  .why-orion-title {
    font-size: 2rem;
    margin-bottom: 35px;
    color: #d30000;
  }
  
  .why-orion-content {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 20px;
    margin-top: 20px;
    justify-content: center;
  }
  
  .reason {
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 10px;
    background-color: white;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s ease-in-out;
  }
  
  .reason:hover {
    transform: scale(1.05);
  }
  
  .reason-icon {
    font-size: 2rem;
    margin-bottom: 10px;
    color: #007bff;
  }
  
  h3 {
    font-size: 1.2rem;
    margin-bottom: 10px;
    color: #444;
  }
  
  p {
    font-size: 1rem;
    color: #666;
  }
}
  
  /* Add more styles and animations as needed */
  