footer {
  margin-top: auto; /* Push the footer to the bottom of the page */
}

.footer-content {
  color: #333; /* Text color for the footer content */
  padding: 30px 10px;
}

.footer-left {
  align-items: center;
}

.footer-logo img {
  width: 100px; /* Adjust the logo width as needed */
}

.footer-address {
  margin-top: 20px;
  flex: 1; /* Allow the address to take up available space */
}

.footer-address p {
  margin: 0;
  font-size: 14px;
}

.footer-social-icons {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 20px;
}

.footer-social-icons img {
  width: 30px; /* Adjust the icon size as needed */
  height: 30px;
}

/* Media query for smaller screens */
@media screen and (max-width: 768px) {
  .footer-left {
    flex-direction: column;
    text-align: center;
  }

  .footer-logo {
    margin-bottom: 20px;
  }

  .footer-social-icons {
    margin-top: 20px;
  }
}
  