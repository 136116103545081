.about-us-component{
    background-color: #f5f5f5;
    #about-us {
        padding: 50px 0;
      }
      
      h2 {
        font-size: 30px;
        font-weight: bold;
        margin-bottom: 20px;
        color: #d30000;
      }
      
      p {
        font-size: 16px;
        line-height: 1.5;
        margin-bottom: 20px;
        text-align: justify;
      }
      
      .chairman {
        display: flex;
        align-items: center;
        margin-top: 50px;
      }
      
      .chairman img {
        width: 200px;
        height: 200px;
        border-radius: 50%;
        margin-right: 50px;
      }
      
      .chairman-message h3 {
        font-size: 24px;
        font-weight: bold;
        margin-bottom: 20px;
      }
      
      .chairman-message p {
        font-size: 18px;
        line-height: 1.5;
      }      
}