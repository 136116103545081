.contact-section {
.contact-mf {
  margin-top: 50px;
}

#contact {
  background-color: #f8f9fa;
  padding: 30px;
  border-radius: 5px;
}

.title-left {
  font-size: 24px;
  font-weight: bold;
  color: #d30000;
  margin-bottom: 20px;
}

.more-info {
  margin-top: 20px;
}

.lead {
  font-size: 16px;
  line-height: 1.6;
  color: #555;
}

.color-a {
  color: #007bff;
}

.socials {
  margin-top: 30px;
}

.socials ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.socials li {
  display: inline-block;
  margin-right: 10px;
}

.socials li:last-child {
  margin-right: 0;
}

.ico-circle {
  display: inline-block;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  border-radius: 50%;
  background-color: #007bff;
}

.ion-university,
.ion-android-mail,
.ion-social-twitter,
.ion-social-linkedin {
  color: #fff;
  font-size: 20px;
}

/* Responsive adjustments for small screens */
@media (max-width: 767px) {
  .contact-mf {
    margin-top: 30px;
  }
  .socials li {
    margin-right: 5px;
  }
  .title-left {
    font-size: 20px;
  }
  .more-info .lead {
    font-size: 14px;
  }
}
}