/* SectorsComponent.css */
.sectors-orion {
/* SectorsComponent.css */
.sectors {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  max-width: 1200px;
  margin: 0 auto;
}

.sector {
  text-align: center;
  margin: 20px;
  flex: 0 0 calc(20% - 40px); /* 3 sectors per row */
  padding: 20px;
  background-color: #f4f4f4;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s, box-shadow 0.2s;
}

.sector:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.icon {
  width: 60px;
  height: 60px;
  margin-bottom: 10px;
}

.sector-name {
  font-size: 14px;
  font-weight: bold;
}

}
