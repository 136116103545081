/* ProjectDetails.css */

.project-details {
  background-color: #f5f5f5;
  padding: 20px;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-align: center;
  padding-bottom: 80px;

  .image {
  width: 100%;
  max-width: 467px;
  height: auto;
}

.title {
  font-size: 36px;
  margin-bottom: 20px;
  color: #d30000;
  font-weight: bold;
  text-decoration: underline;
}

.image-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  height: 26rem;
}
.image-container img {
  width: 30%;
  border: 1px solid #ddd;
  border-radius: 5px;
  transition: transform 0.3s ease-in-out;
}

.image-container img:hover {
  transform: scale(1.05);
}

.list-container {
  list-style: none;
  padding: 0;
  text-align: left;
}

.list-item {
  font-size: 16px;
  margin-bottom: 10px;
  color: #555;
}
.head-top {
  font-size: 30px;
  font-weight: bold;
  color:#d30000;
}
}
@media only screen and (max-width: 768px) {
  .project-details {
    .image-container {
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;
      height: 10rem;
      padding: 0 !important;
    }
  }

}
