/* Container styles */
.news-and-blog-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 40px 0;
  }
  
  h2 {
    font-size: 30px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 20px;
    color: #d30000;
  }
  
  /* Card container styles */
  .news-cards-container {
    display: flex;
    overflow-x: auto; /* Enable horizontal scrolling */
    padding-bottom: 10px; /* Add some space at the bottom */
    scrollbar-width: none; /* Hide default scrollbar for Firefox */
  }
  
  .news-cards-container::-webkit-scrollbar {
    display: none; /* Hide default scrollbar for Webkit-based browsers */
  }
  
  .news-card {
    width: 400px;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    padding: 20px;
    margin-right: 20px;
    flex-shrink: 0; /* Prevent cards from shrinking */
  }
  
  .news-card img {
    width: 100%;
    height: 150px;
    object-fit: cover;
    border-radius: 5px;
  }
  
  .news-card h3 {
    font-size: 1.5rem;
    font-weight: bold;
    margin-top: 10px;
  }
  
  .news-card p {
    font-size: 1rem;
    margin-top: 10px;
    color: #444;
    text-align: justify;
  }


.read-more-btn {
  background-color: #d30000;
  color: #fff;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.read-more-btn:hover {
  background-color: #ff2800;
}