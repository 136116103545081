.client-list {
  background-color: #f2f2f2;
  padding: 20px;
}

.client-list h2 {
  font-size: 30px;
  margin-bottom: 10px;
  color: #d30000;
}

.client-list ul {
  list-style-type: none;
  padding: 0;
}

.client-list li {
  font-size: 16px;
  margin-bottom: 5px;
  padding: 8px 12px;
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease-in-out;
}
.black-text {
  font-size: 24px !important;
  font-weight: 600;

}

.client-list li:hover {
  background-color: #f0f0f0;
}

.card-img-top{
  aspect-ratio: 3/2;
  object-fit: contain;
  
}