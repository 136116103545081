/* CarouselComponent.css */
.orion-carousel {
.carousel {
    position: relative;
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .hero-section {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  }
  
  .banner-image {
    max-width: 100%;
    height: auto;
  }
  
  .prev-btn,
  .next-btn {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    font-size: 24px;
    background: none;
    border: none;
    cursor: pointer;
  }
  
  .prev-btn {
    left: 10px;
  }
  
  .next-btn {
    right: 10px;
  }

.indicators {
    display: flex;
    justify-content: center;
    margin-top: 10px;
    position: absolute;
    top: 55rem;
    @media screen and (max-width: 1024px) {
        top: 28rem;
      }
      @media screen and (max-width: 500px) {
          top: 15rem;
        }
  }
  
  .indicator {
    width: 10px;
    height: 10px;
    background-color: #ccc;
    border-radius: 50%;
    margin: 0 5px;
    cursor: pointer;
  }
  
  .active {
    background-color: #333;
  }
}
  