
.company-name {
  display: flex;
  justify-content: center;
  color: #fff;
  font-size: 70px;
  font-weight: bold;
  text-shadow: 2px 2px #000;
}

.company-title {
  color: #fff;
  font-size: 40px;
  font-weight: bold;
  text-shadow: 2px 2px #000;
  white-space: nowrap;
}

/* Responsive styles */
@media only screen and (max-width: 768px) {
  .company-name { 
    font-size: 40px;
  }

  .company-title {
    font-size: 14px;
    top: 69%;
    white-space: nowrap;
  }
}

@media only screen and (min-width: 720px) and (max-width: 850px) {
  .company-name {
    font-size: 40px;
  }

  .company-title {
    font-size: 30px;
    top: 60%;
  }
}