.header {
  background-color: #ffffff;
  height: 80px;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  position: sticky;
  top: 0;
  z-index: 999;
}
.company-log-container {
  display: flex;
  flex-direction: column;
  text-decoration: none;
  .company-tagline {
    color: #c71d22;
    font-weight: bold;
  }
}

.logo {
  height: 50px;
}

.navigation {
  display: flex;
  align-items: center;
}

.nav-list {
  list-style: none;
  display: flex;
}

.nav-item {
  margin-right: 20px;
}

.nav-item:last-child {
  margin-right: 0;
}

.nav-item a {
  text-decoration: none;
  color: #333;
  font-weight: bold;
}

.nav-item a:hover {
  color: #ff2800;
}

.nav-item a {
  color: #d30000;
}

/* Responsive styles */
@media only screen and (max-width: 768px) {
  .header {
    display: block;
    flex-direction: column;
    height: auto;
    padding: 20px;
  }

  .logo {
    margin-bottom: 10px;
  }

  .navigation {
    flex-direction: column;
    align-items: flex-start;
    overflow: auto;
  }

  .nav-item {
    margin-right: 0;
    margin-bottom: 10px;
  }
}
  